import React from "react";
import DemoCarousel from "./carousel";

import "./styles.css";

function Portfolio() {
  return (
    <div className="DemoCarousel">
      <DemoCarousel />
    </div>
  );
}

export default Portfolio;
